@charset "utf-8";

// Our variables
$base-font-family: sans-serif, Helvetica, Arial, '宋体';
$base-font-size:   15px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 2;

$spacing-unit:     30px;

$text-color:       #111;
$background-color: #fdfdfd;
$brand-color:      #2a7ae2;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$content-width:    800px;

$on-palm:          600px;
$on-laptop:        800px;

@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "font",
        "nav",
        "base",
        "card",
        "layout",
        "syntax-highlighting",
        "slick_override",
        "helper"
;

@import '../../node_modules/slick-carousel/slick/slick.scss';
@import '../../node_modules/slick-carousel/slick/slick-theme.scss';
