// not in use
.row {
  margin-right: -15px;
  margin-left: -15px;
  border-bottom: 1px solid #e8e8e8;
}

.row:before,
.row:after{
  display: table;
  content: " ";
}
.row:after{
  clear: both;
}

.col-xs-12, .col-xs-6, .col-sm-5, .col-sm-7{
  float:left;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  margin: 0;
  padding: 0;
  vertical-align:top;
}

.col-xs-12 {
  width: 100%;
}
.col-xs-6 {
  width: 50%;
}

@media (min-width: 768px) {
  .col-sm-7{
      width: 58.3333333333%;
  }
  .col-sm-5{
      width: 41.6666666667%;
  }
}

@media (max-width: 767px) {
  .col-xs-12 {
    width: 100%;
  }
}

.section-heading{
  color:orange;
}

.card{
    position: relative;
    margin: 0.5rem 0 1rem 0;
    background-color: #fff;
    transition: box-shadow .25s;
    border-radius: 2px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
    width:100%;
    .card-image{
      background-position:center;
      height:300px;
      width:auto;
      background-size: cover;
    }

    .card-content{
      padding: 20px;
      border-radius: 0 0 2px 2px;
    }

}

.card-desc{
  padding: 20px;
  border-radius: 0 0 2px 2px;

  .card-desc-title{
    margin-top:40px;
  }
}
