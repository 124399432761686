@font-face {
  font-family: "cheeger";
  src:url("../fonts/cheeger.eot");
  src:url("../fonts/cheeger.eot?#iefix") format("embedded-opentype"),
    url("../fonts/cheeger.woff") format("woff"),
    url("../fonts/cheeger.ttf") format("truetype"),
    url("../fonts/cheeger.svg#cheeger") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "cheeger" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "cheeger" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-align-justify:before {
  content: "\61";
}
.icon-android:before {
  content: "\62";
}
.icon-amazon:before {
  content: "\63";
}
.icon-bolt:before {
  content: "\64";
}
.icon-btc:before {
  content: "\66";
}
.icon-git:before {
  content: "\68";
}
.icon-device-mobile:before {
  content: "\69";
}
.icon-file-media:before {
  content: "\6a";
}
.icon-unlock:before {
  content: "\6b";
}
.icon-aws:before {
  content: "\6c";
}
.icon-github-square:before {
  content: "\6d";
}
.icon-thumb-tack:before {
  content: "\6e";
}
.icon-file-text:before {
  content: "\6f";
}
.icon-file-text-o:before {
  content: "\70";
}
.icon-user:before {
  content: "\71";
}
.icon-rss-square:before {
  content: "\72";
}
.icon-commenting-o:before {
  content: "\73";
}
.icon-apple:before {
  content: "\74";
}
.icon-desktop:before {
  content: "\75";
}
.icon-server:before {
  content: "\76";
}
.icon-database:before {
  content: "\77";
}
.icon-connectdevelop:before {
  content: "\78";
}
.icon-network:before {
  content: "\79";
}
.icon-banknote:before {
  content: "\7a";
}
.icon-play-circle:before {
  content: "\41";
}
.icon-code:before {
  content: "\42";
}
.icon-html5:before {
  content: "\43";
}
.icon-ruby:before {
  content: "\44";
}
.icon-picture-o:before {
  content: "\45";
}
.icon-hdd-o:before {
  content: "\46";
}
.icon-language:before {
  content: "\47";
}
.icon-envelope-o:before {
  content: "\48";
}
.icon-envelope:before {
  content: "\49";
}
.icon-github-alt:before {
  content: "\4a";
}
.icon-github:before {
  content: "\4b";
}
.icon-graduation-cap:before {
  content: "\4c";
}
.icon-briefcase:before {
  content: "\4d";
}
.icon-atom:before {
  content: "\4e";
}
.icon-signal:before {
  content: "\4f";
}
.icon-internet-explorer:before {
  content: "\65";
}
.icon-chrome:before {
  content: "\67";
}
.icon-vimeo-square:before {
  content: "\50";
}
